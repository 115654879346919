<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pt-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <v-row>
          <v-col cols="10" class="p-n6">
            <PageHeader :title="title" />
          </v-col>
          <v-col cols="2" class="py-0">
            <v-tooltip left class="pt-10">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  color="primary"
                  @click="goToHistoryConfig()"
                  ><v-icon size="30px">{{ listIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a log de configuraciones</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-container class="py-0 px-0">
          <v-data-table
            :headers="headers"
            :items="itemsConfigAutorizaciones"
            height="100%"
            class="elevation-1"
            :loading="isLoadingTable"
            :search="search"
            item-key="mprovConfigAutoId"
          >
            <template v-slot:[`item.listaUsuario`]="{ item }"
              ><span v-for="(usu, index) in item.listaUsuario" :key="index"
                >{{ usu.usuario
                }}{{ index < item.listaUsuario.length - 1 ? "," : "" }}
              </span></template
            >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModalNewConfig()"
                      align="end"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalNewConfig(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteAutorizacion(item.mprovConfigAutoId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-col class="pt-2 pl-0" cols="12">
            <span style="font-size: 15px">
              <b>IMPORTANTE:</b> Todo comprobante que exceda el mínimo “importe
              desde” va a requerir autorización. Si el rango de importes no está
              configurado no se podrá autorizar, pero se informará el error de
              configuración al momento de intentar pagarlo. Se recomienda
              ingresar todos los rangos necesarios hasta el mayor “Importe
              hasta” posible.</span
            >
          </v-col>
        </v-container> </v-col
      ><!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-2 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalNuevo"
      v-model="openModalNuevo"
      max-width="45%"
      @keydown.esc="openModalNuevo = false"
      persistent
    >
      <EditConfiguracionAutorizacionesProveedor
        :itemToEdit="itemToEdit"
        @closeAndReload="closeAndReload"
      >
      </EditConfiguracionAutorizacionesProveedor>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :isLoading="loadingBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import GoBackBtn from "@/components/shared/GoBackBtn";
import PageHeader from "@/components/ui/PageHeader";
import Ayuda from "@/components/shared/Ayuda.vue";
import { mask } from "vue-the-mask";
import helpers from "@/utils/helpers.js";
import EditConfiguracionAutorizacionesProveedor from "@/components/modules/proveedores/EditConfiguracionAutorizacionesProveedor.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  name: "ConfiguracionAutorizacionesProveedor",
  components: {
    Ayuda,
    GoBackBtn,
    FiltersSelected,
    PageHeader,
    DeleteDialog,
    EditConfiguracionAutorizacionesProveedor,
  },
  directives: { mask },
  data: (vm) => ({
    rules: rules,
    showExpand: false,
    helpers: helpers,
    searchIcon: enums.icons.SEARCH,
    search: "",
    titleDelete: "¿Desea eliminar la configuración?",
    title: "Configuración autorizaciones a pagar", //enums.titles.COMPROBANTES_PROVEEDOR,
    deleteIcon: enums.icons.DELETE,
    editIcon: enums.icons.EDIT,
    canDelete: false,
    canCreate: false,
    canEdit: false,
    listIcon: enums.icons.UL,
    optionCode: enums.webSiteOptions.AUTORIZACION_FACTURAS_PROVEEDOR_CONFIG,
    routeToGo: "AutorizacionesProveedor",
    showHelp: false,
    isFormValid: false,
    showFilters: true,
    allowedActions: null,
    filtersApplied: [],
    isLoadingTable: false,
    loadingBtnDelete: false,
    itemsConfigAutorizaciones: [],
    openModalNuevo: false,
    showDeleteModal: false,
    idToDelete: null,
    headers: [
      {
        text: "Empresa",
        sortable: false,
        value: "entFacNombre",
      },
      {
        text: "Moneda",
        sortable: false,
        value: "monedaNombre",
      },
      {
        text: "Importe desde",
        sortable: false,
        align: "right",
        value: "importeDesdeToShow",
      },
      {
        text: "Importe hasta",
        sortable: false,
        align: "right",
        value: "importeHastaToShow",
      },
      {
        text: "Usuario",
        sortable: false,
        align: "left",
        value: "listaUsuario",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        width: "12%",
        align: "end",
      },
    ],
  }),
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
  },
  async created() {
    this.loadAutorizacionesConfig();
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getAutorizacionesAPagarConfig:
        "proveedores/getAutorizacionesAPagarConfig",
      deleteConfigAutorizacionesAPagar:
        "proveedores/deleteConfigAutorizacionesAPagar",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_CONFIG_AUTORIZACION:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_CONFIG_AUTORIZACION:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_CONFIG_AUTORIZACION:
            this.canEdit = true;
            break;
          default:
            break;
        }
      });
    },
    async loadAutorizacionesConfig() {
      try {
        this.isLoadingTable = true;
        const response = await this.getAutorizacionesAPagarConfig({
          entFacId: null,
        });
        this.itemsConfigAutorizaciones = response;
      } catch (err) {}
      this.isLoadingTable = false;
    },
    deleteAutorizacion(id) {
      this.idToDelete = id;
      this.showDeleteModal = true;
    },
    async confirmDelete() {
      this.loadingBtnDelete = true;
      try {
        const response = await this.deleteConfigAutorizacionesAPagar(
          this.idToDelete
        );
        if (response.status == 200) {
          this.showDeleteModal = false;
          this.setAlert({
            type: "success",
            message: "Configuración eliminada con exito",
          });
          this.loadingBtnDelete = false;
          this.loadAutorizacionesConfig();
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.loadingBtnDelete = false;
      }
    },
    goToHistoryConfig() {
      this.$router.push({
        name: "LogConfiguracionesGenerales",
        params: {
          routeToGo: "ConfiguracionAutorizacionesProveedor",
          tipoConfigId: 3,
        },
      });
    },
    closeAndReload() {
      this.openModalNuevo = false;
      this.loadAutorizacionesConfig();
    },
    openModalNewConfig(item) {
      this.openModalNuevo = true;
      this.itemToEdit = item;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>

