var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pt-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('v-row',[_c('v-col',{staticClass:"p-n6",attrs:{"cols":"10"}},[_c('PageHeader',{attrs:{"title":_vm.title}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-tooltip',{staticClass:"pt-10",attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"to-right",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.goToHistoryConfig()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30px"}},[_vm._v(_vm._s(_vm.listIcon))])],1)]}}])},[_c('span',[_vm._v("Acceder a log de configuraciones")])])],1)],1),_c('v-container',{staticClass:"py-0 px-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsConfigAutorizaciones,"height":"100%","loading":_vm.isLoadingTable,"search":_vm.search,"item-key":"mprovConfigAutoId"},scopedSlots:_vm._u([{key:"item.listaUsuario",fn:function(ref){
var item = ref.item;
return _vm._l((item.listaUsuario),function(usu,index){return _c('span',{key:index},[_vm._v(_vm._s(usu.usuario)+_vm._s(index < item.listaUsuario.length - 1 ? "," : "")+" ")])})}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"6","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary","align":"end"},on:{"click":function($event){return _vm.openModalNewConfig()}}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModalNewConfig(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteAutorizacion(item.mprovConfigAutoId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e()]}}],null,true)}),_c('v-col',{staticClass:"pt-2 pl-0",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"15px"}},[_c('b',[_vm._v("IMPORTANTE:")]),_vm._v(" Todo comprobante que exceda el mínimo “importe desde” va a requerir autorización. Si el rango de importes no está configurado no se podrá autorizar, pero se informará el error de configuración al momento de intentar pagarlo. Se recomienda ingresar todos los rangos necesarios hasta el mayor “Importe hasta” posible.")])])],1)],1),_c('v-col',{staticClass:"to-right pt-2 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1)],1),(_vm.openModalNuevo)?_c('v-dialog',{attrs:{"max-width":"45%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.openModalNuevo = false}},model:{value:(_vm.openModalNuevo),callback:function ($$v) {_vm.openModalNuevo=$$v},expression:"openModalNuevo"}},[_c('EditConfiguracionAutorizacionesProveedor',{attrs:{"itemToEdit":_vm.itemToEdit},on:{"closeAndReload":_vm.closeAndReload}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'35%',"isLoading":_vm.loadingBtnDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }