<template>
  <v-card>
    <v-container>
      <v-card-title class="mt-n3">
        <span class="primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveConfig()"
        >
          <v-row>
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="empresaSelected"
                :items="empresasItems"
                item-text="entFacNombre"
                item-value="entFacId"
                clearable
                :disabled="itemToEdit !== null"
                return-object
                :rules="rules.required"
                @change="setMonedaByEntFac()"
                label="Empresa"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-autocomplete
                v-model="monedaSelected"
                :items="monedasItems"
                item-text="value"
                item-value="id"
                :disabled="disabledMoneda || itemToEdit !== null"
                clearable
                return-object
                :rules="rules.required"
                label="Moneda"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <currency-input
                v-model.trim="importeDesde"
                label="Importe desde"
                :options="currencyOptions"
                class="right-input"
                :rules="
                  rules.required.concat(
                    rules.validDateRange(
                      parseInt(importeDesde),
                      parseInt(importeHasta)
                    )
                  )
                "
              ></currency-input
            ></v-col>
            <v-col cols="6" class="py-0">
              <currency-input
                v-model.trim="importeHasta"
                label="Importe hasta"
                :options="currencyOptions"
                class="right-input"
                :rules="
                  rules.required.concat(
                    rules.validDateRange(
                      parseInt(importeDesde),
                      parseInt(importeHasta)
                    )
                  )
                "
              ></currency-input
            ></v-col>
            <v-col cols="12" class="py-0 pt-3">
              <v-autocomplete
                v-model="usuariosSelected"
                :items="usuariosItems"
                item-text="usuario"
                item-value="usuario"
                label="Usuarios"
                outlined
                multiple
                dense
                clearable
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="usersToggle()">
                    <v-list-item-action>
                      <v-icon :color="selectAllUsers ? 'primary' : ''">
                        {{
                          selectAllUsers
                            ? "mdi-checkbox-marked"
                            : "mdi-checkbox-blank-outline"
                        }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-n8 mr-2">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid || usuariosSelected.length === 0"
          form="form"
          :loading="loadingBtnSave"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "EditConfiguracionAutorizacionesProveedor",
  components: { CurrencyInput },
  directives: { mask },
  props: {
    itemToEdit: { type: Object, required: false, default: null },
  },
  data: () => ({
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "hidden",
      valueRange: {
        min: 0,
        max: 999999999999999,
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: true,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true,
    },
    rules: rules,
    formEditTitle: "Nueva configuración",
    calendarIcon: enums.icons.CALENDAR,
    empresaSelected: null,
    empresasItems: [],
    monedaSelected: null,
    monedasItems: [],
    importeHasta: null,
    importeDesde: null,
    usuariosSelected: [],
    usuariosItems: [],
    isFormValid: false,
    loadingBtnSave: false,
    disabledMoneda: false,
  }),
  created() {
    this.setSelectes();
    if (this.itemToEdit !== null) {
      this.formEditTitle = "Editar configuración autorización";
      this.setConfigAutorizacion();
    }
  },
  computed: {
    selectAllUsers() {
      return (
        this.usuariosSelected &&
        this.usuariosSelected.length === this.usuariosItems.length
      );
    },
  },
  methods: {
    ...mapActions({
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getEmpresaXMoneda: "proveedores/getEmpresaXMoneda",
      getUsuariosParaAutorizar: "proveedores/getUsuariosParaAutorizar",
      saveConfigAutorizaciones: "proveedores/saveConfigAutorizaciones",
      setAlert: "user/setAlert",
    }),
    async setSelectes() {
      const monedas = await this.getMonedasTaxonomy("habListado");
      this.monedasItems = monedas;
      this.monedaSelected = monedas.find((x) => x.bool);
      const entidadesFacturantes = await this.getEmpresaXMoneda();
      this.empresasItems = entidadesFacturantes;
      const users = await this.getUsuariosParaAutorizar();
      this.usuariosItems = users;
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    setConfigAutorizacion() {
      this.monedaSelected = this.itemToEdit.monedaId;
      this.importeDesde = this.itemToEdit.importeDesde;
      this.importeHasta = this.itemToEdit.importeHasta;
      this.itemToEdit.listaUsuario.forEach((x) => {
        this.usuariosSelected.push(x.usuario);
      });
      this.empresaSelected = { entFacId: this.itemToEdit.entFacId };
    },
    usersToggle() {
      this.$nextTick(() => {
        if (this.selectAllUsers) this.usuariosSelected = [];
        else this.usuariosSelected = this.usuariosItems.map((x) => x.usuario);
      });
    },
    setMonedaByEntFac() {
      if (this.empresaSelected && this.empresaSelected.monedaId !== 0) {
        this.monedaSelected = { id: this.empresaSelected.monedaId };
        this.disabledMoneda = true;
      } else {
        this.monedaSelected = null;
        this.disabledMoneda = false;
      }
    },
    async saveConfig() {
      this.loadingBtnSave = true;
      const data = {
        mprovConfigAutoId: this.itemToEdit?.mprovConfigAutoId ?? null,
        entFacId: this.empresaSelected.entFacId,
        monedaId: this.monedaSelected.id,
        importeDesde: parseFloat(this.importeDesde),
        importeHasta: parseFloat(this.importeHasta),
        listaUsuario: this.usuariosSelected,
      };
      try {
        const response = await this.saveConfigAutorizaciones(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
        }
      } catch (e) {}
      this.loadingBtnSave = false;
    },
  },
};
</script>



<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
